body{
  font-family: 'Roboto', sans-serif;
}
.Navbar{
  position: fixed;
  bottom: 0;
  min-width: 100%;
  color: #8f9498;
  padding-bottom: 0.5rem;
  background-color: white;
  border-top:1px solid #cad2d8;
}
.Wrapper{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: minmax(50px, auto);
}
.one {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor:pointer;
}
.two {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor:pointer;
}
.three {
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor:pointer;
}
.active{
  color: #44e6e8;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .Wrapper{
    max-width: 50vh;
    margin: auto;
  }
}