.Modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModalCover{
    position: absolute;
    left: 0;
    right: 0;
    min-width: 100vh;
    height: 100vh;
    z-index: -2;
    background-color: white;
    opacity: 0.3;
}
.ModalContent{
    color: white;
    background: rgba(0,0,0,0.9);
    position: relative;
    border-radius: .5rem;
    padding: 1.5rem;
    max-height: 80vh;
    min-width: 80%;
    max-width: 80%;
    font-size: 1.2em;
    overflow:auto;
}
.sectionButtonsModal{
    margin-top: 1em;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    font-size: 0.5em;
    grid-column-gap: 0.5em;
    justify-content: center;
    align-items: center;
}
.buttonModal{
    text-align: center;
    padding: 0.5em;
    border: 1px solid white;
    border-radius: 1em;
    cursor: pointer;
}
.buttonClose{
    position: absolute;
    background: none;
    border: 1px solid white;
    border-radius: 1em;
    color: white;
    text-align: center;
    right: 0;
    margin-right: 2em;
}
.incluye{
    font-size: 0.7em;
}
.displayGrid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
@media (min-width: 768px) {
    .ModalContent{
        min-width: 30%;
        max-width: 30%;
    }
    .ModalContent::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .ModalContent::-webkit-scrollbar:vertical {
        width:5px;
    }
    
    .ModalContent::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
        display: none;
    } 
    .ModalContent::-webkit-scrollbar-thumb {
        background-color: #292929;
        border-radius: 20px;
    }
    
    .ModalContent::-webkit-scrollbar-track {
        border-radius: 10px;  
    }

}